
body,html{
  height:100%;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size:16px;
}
#table-holder{
  overflow-x: scroll;
}
#csv-table{
 
 td{
  
 }
  
   .info-td{
    span{
      display: block;
    }
    .new{
      color:red;
    }
   }
}
.fixed.col + .col{



}
.col.fixed{
 
  width:200px;
  max-width: 220px;
  background-color: #2d287a;
  color:white;
}
.form-label{
  font-weight: bold;
}
.fromcontent .row{
  margin-bottom:1em;
}
.col.check{
  align-items: center;
  display: flex;
}
.container-fluid{
  header{
    background-color:#cd3733;
    color:white;
    display: flex;
    padding:1em;
    align-items: center;
    h1{
      font-size:1.3em;
      text-transform: capitalize;
      padding-right:1em;
    }
    a{
      color:white;
    }
    .buttons{
    
    }
  
  }
  min-height: 100vh;
  &>.row{
    min-height: 100vh;
    flex-wrap: nowrap;
    img{
      width:100%;
      height: auto;
      padding-top:1em;
      padding-bottom:1em;
    }
    .contentarea{
      max-width: calc(100% - 200px);
      width: calc(100% - 200px);
     
      overflow: hidden;

    }
  }
  .contentarea{
   
    padding:0;
   div.content{
      padding:1em;
    }
  }
}
nav{
  ul{
    padding-left:0;
    list-style: none;
    li{
      margin-top:.5em;
      margin-bottom:.5em;
      a{
        color:white;
        text-decoration: none;
        &.active{
          font-weight: bold;
        }
      }
    }
  }
}